var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{ref:"orders-table",attrs:{"columns":_vm.columns,"filters":_vm.baseFilters,"totals-configuration":_vm.totalsConfiguration,"resource":_vm.$data.$constants.RESOURCES.RESOURCE_ORDERS,"resourceStore":'orders',"loading":_vm.loading,"excluded-filters":_vm.baseExcludeFilters},on:{"filter-button-click":function($event){_vm.filtersVisible = !_vm.filtersVisible},"on-clear-active-filters":_vm.handleClearOfferListFilters,"row-clicked":function($event){return _vm.$router.push({ name: 'viewOrder', params: { id: $event.id } })}},scopedSlots:_vm._u([{key:"cell(job)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.job.code)+" ")])]}},{key:"cell(order_date)",fn:function(data){return [_vm._v(" "+_vm._s(new Date(data.item.order_date).toLocaleDateString())+" ")]}},{key:"cell(provider)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.provider.name)+" ")])]}},{key:"cell(pending_billing)",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("numberToLocalString")(value))+" €")])]}},{key:"cell(total_gross)",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("numberToLocalString")(value))+" €")])]}},{key:"cell(total_order)",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("numberToLocalString")(value))+" €")])]}},{key:"cell(status)",fn:function(data){return [_c('StatusBadge',{attrs:{"status":data.item.status.name,"text":_vm.getStatusText(data.item)}})]}},{key:"cell(status_notes)",fn:function(data){return [_c('StatusBadge',{attrs:{"status":data.item.status_notes.name}})]}},{key:"cell(actions)",fn:function(data){return [_c('b-link',{staticClass:"d-inline-block text-indigo"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Descargar pedido","icon":"DownloadIcon","size":"18"},on:{"click":function($event){$event.stopPropagation();return _vm.handlePrintLinkClick(data.item.id)}}})],1),_c('b-link',{staticClass:"px-1 d-inline-block text-indigo",attrs:{"to":{ name: 'viewOrder', params: { id: data.item.id} }}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Ver","icon":"EyeIcon","size":"18"}})],1),_c('b-link',{directives:[{name:"access",rawName:"v-access",value:({
          resource: _vm.$data.$constants.RESOURCES.RESOURCE_ORDERS,
          resourceAction: _vm.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
        }),expression:"{\n          resource: $data.$constants.RESOURCES.RESOURCE_ORDERS,\n          resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL\n        }"}],staticClass:"d-inline-block text-danger",on:{"click":function($event){return _vm.handleDeleteIconClick(data.item)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Eliminar","icon":"TrashIcon","size":"18"}})],1)]}}])}),_c('OrdersListFilters',{ref:"orders-list-filters",on:{"filters-submit":function($event){return _vm.$store.commit('orders/setFilters', $event)}},model:{value:(_vm.filtersVisible),callback:function ($$v) {_vm.filtersVisible=$$v},expression:"filtersVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }